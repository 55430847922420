import { AsyncPipe, Location, NgClass } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import {
  combineLatest,
  forkJoin,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { City, Community, District, LiveArea } from '@alan-apps/data-access';
import { toNodes } from '@alan-apps/utils';
import { ControlContainerProvider } from '@nghedgehog/angular-ui';
import { useTakeUntilDestroyed } from '@nghedgehog/core';
import { TranslateModule } from '@ngx-translate/core';

import { CommunityService } from '../../../service/community.service';
import { LiveAreaService } from '../../../service/live-area.service';
import { MrtService } from '../../../service/mrt.service';
import { CheckedItem, SystemService } from '../../../service/system.service';
import { MySelectOptionComponent } from '../my-select-option/my-select-option.component';
import { input } from '@angular/core';

@Component({
  selector: 'app-multi-select-address',
  templateUrl: './multi-select-address.component.html',
  styleUrls: ['./multi-select-address.component.scss'],
  viewProviders: [ControlContainerProvider],
  standalone: true,
  imports: [
    MySelectOptionComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    AsyncPipe,
    TranslateModule,
  ],
})
export class MultiSelectAddressComponent implements OnInit {
  private _system = inject(SystemService);
  private _route = inject(ActivatedRoute);
  public controlContainer = inject(ControlContainer);
  public _mrt = inject(MrtService);
  public _liveArea = inject(LiveAreaService);
  public _community = inject(CommunityService);
  readonly takeUntilAppDestroy = useTakeUntilDestroyed();
  queries = input([]);
  onlyProperty = input(false);
  citiesInput$ = input<Observable<City[]> | undefined>(undefined, {
    alias: 'cities$',
  });

  active = 'city';
  cityDistrictArray: (City & {
    districts: District[];
    startIndex: number;
  })[] = [];

  get formGroup() {
    return this.controlContainer.control;
  }

  get cities() {
    return this.formGroup.get('cities') as FormArray;
  }
  get districts() {
    return this.formGroup.get('districts') as FormArray;
  }
  get liveAreas() {
    return this.formGroup.get('liveAreas') as FormArray;
  }
  get communities() {
    return this.formGroup.get('communities') as FormArray;
  }
  get educationSchools() {
    return this.formGroup.get('educationSchools') as FormArray;
  }

  get transportationFacilities() {
    return this.formGroup.get('transportationFacilities') as FormArray;
  }

  cities$: Observable<City[]>;

  transportationFacilitiesCategories$ = this._mrt.getAllMassTransit().pipe(
    map((categories) => {
      let startIndex = 0;

      return categories.map((category, i) => {
        category.mrts.forEach((mrt) => {
          this.transportationFacilities.push(
            this._system.createCheckedFormGroup({
              id: mrt.id,
              name: mrt.name,
            }),
            {
              emitEvent: false,
            },
          );
        });

        startIndex += categories[i - 1]?.mrts.length || 0;
        return { ...category, startIndex };
      });
    }),
    shareReplay(1),
  );

  liveAreas$ = this._liveArea.list({ skip: 0, take: 999 }).pipe(
    toNodes(),
    tap((liveAreas: LiveArea[]) => {
      liveAreas.forEach((liveArea) => {
        this.liveAreas.push(
          this._system.createCheckedFormGroup({
            id: liveArea.id,
            name: liveArea.name,
          }),
          {
            emitEvent: false,
          },
        );
      });
    }),
    shareReplay(1),
  );

  communities$ = this._community.list({ skip: 0, take: 999 }).pipe(
    toNodes(),
    tap((_communities: Community[]) => {
      _communities.forEach((_community) => {
        this.communities.push(
          this._system.createCheckedFormGroup({
            id: _community.id,
            name: _community.name,
          }),
          {
            emitEvent: false,
          },
        );
      });
    }),
    shareReplay(1),
  );

  ngOnInit(): void {
    this.cities$ = this.citiesInput$().pipe(
      tap((cities) => {
        cities.forEach((city) => {
          this.cities.push(
            this._system.createCheckedFormGroup({
              id: city.id,
              name: city.name,
            }),
            {
              emitEvent: false,
            },
          );
          this.cities.updateValueAndValidity();
        });
      }),
      shareReplay(1),
    );
    this.listenAddress();

    combineLatest([
      this._route.queryParams,
      this.transportationFacilitiesCategories$,
      this.liveAreas$,
      this.communities$,
      this.cities$,
    ])
      .pipe(
        tap(([params]) => {
          Object.entries(params).forEach((curr) => {
            this._system.checkRouteParams(curr, this.formGroup);
          });
        }),
        this.takeUntilAppDestroy(),
      )
      .subscribe();
  }

  // http://localhost:4200/properties/page/1?category=house&type=1&cityId=16

  private listenAddress() {
    this.cities.valueChanges
      .pipe(
        map((cities: CheckedItem[]) => cities.filter((x) => x.checked)),
        switchMap((cities) => {
          const currDistrictValue = this.districts.value;
          this.districts.clear({ emitEvent: false });

          if (cities.length === 0) {
            return of([]);
          }

          return forkJoin(
            cities.map((city) =>
              this._system.getCityDistricts(city.id, this.onlyProperty()),
            ),
          ).pipe(
            map((districtsArr) => {
              let startIndex = 0;

              const checkedItems = currDistrictValue.filter((x) => x.checked);

              const cityDistrictArray = districtsArr.map((districts, i) => {
                districts.forEach((district) => {
                  const checked = checkedItems.some(
                    (y) => y.id === district.id,
                  );

                  this.districts.push(
                    this._system.createCheckedFormGroup(
                      {
                        id: district.id,
                        name: district.name,
                      },
                      checked,
                    ),
                    {
                      emitEvent: false,
                    },
                  );
                });
                startIndex += districtsArr[i - 1]?.length || 0;
                return { ...cities[i], districts, startIndex };
              });

              return cityDistrictArray;
            }),
          );
        }),
        tap((cityDistrictArray) => {
          this.cityDistrictArray = cityDistrictArray;
          this.districts.updateValueAndValidity();
        }),
        tap(() => {
          const key = 'districts';
          const value = new URLSearchParams(location.search).get('districts');

          if (value) {
            this._system.checkRouteParams([key, value], this.formGroup);
          }
        }),
      )
      .subscribe();
    // get districts
  }
}
