import { Component, EventEmitter, Input, Output, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { City } from '@alan-apps/data-access';
import { useTakeUntilDestroyed } from '@nghedgehog/core';

import { PropertyService } from '../../../service/property.service';
import { SaveQuery } from '../../../service/state-condition.handler';
import { input } from '@angular/core';
import { output } from '@angular/core';

@Component({
  selector: '',
  template: '<div></div>',
})
export class BaseSearchPropertiesComponent {
  protected _property = inject(PropertyService);
  readonly takeUntilAppDestroy = useTakeUntilDestroyed();
  queryChange = output<SaveQuery[]>();
  clear = output<boolean>();

  get queries() {
    return this._queries;
  }
  set queries(value) {
    this._queries = value;
    this.queryChange.emit(this.queries);
  }
  _queries: SaveQuery[] = [];

  cities$ = input<Observable<City[]> | undefined>();

  setValue(target, targetName: string, choiceOne = false) {
    this.queries = [
      ...this.queries,
      ...this._property.setValue(this, target, targetName, choiceOne),
    ];
  }
}
