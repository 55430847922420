export const toBoolean = (result) => !!result;
export const toNumber = (result) => {
  if (result !== '') {
    return +result;
  }
  return -1;
};
export const toLiteral = (result) => `${result}`;

export class StateConditionHandler {
  constructor(private instance: any, private queryParams: any) {}

  checkQueryState({
    sourceKey,
    fromKey,
    sourceDataHandler,
    targetDataHandler,
  }: StateCondition) {
    const queries = [];

    const prevQueries: string[] = (this.instance[sourceKey].value || '')
      .split(',')
      .filter((x) => !!x);

    const nextQueries: string[] = (this.queryParams[sourceKey] || '')
      .split(',')
      .filter((x) => !!x);

    this.instance[sourceKey].patchValue(this.queryParams[sourceKey]);

    const clearItems = differenceItems(prevQueries, nextQueries);

    clearItems.forEach((x) => {
      const sourceTmp = sourceDataHandler ? sourceDataHandler(x) : x;

      const resultIndex = this.instance[fromKey].findIndex((k) => {
        const tmpValue = targetDataHandler
          ? targetDataHandler(k.value)
          : k.value;
        return tmpValue === sourceTmp;
      });

      if (resultIndex !== -1) {
        this.instance[fromKey][resultIndex].checked = false;
      }
    });

    // reset source item
    nextQueries.forEach((x) => {
      const sourceTmp = sourceDataHandler ? sourceDataHandler(x) : x;

      const resultIndex = this.instance[fromKey].findIndex((k) => {
        const tmpValue = targetDataHandler
          ? targetDataHandler(k.value)
          : k.value;
        return tmpValue === sourceTmp;
      });

      if (resultIndex !== -1) {
        this.instance[fromKey][resultIndex].checked = true;

        queries.push(<SaveQuery>{
          data: this.instance[fromKey][resultIndex],
          key: 'title',
          delete: (data) => {
            this.instance[fromKey][resultIndex].checked = false;
          },
        });
      }
    });

    return queries;
  }
}

function differenceItems(source: any[], target: any[]) {
  const set1 = new Set(source);
  const set2 = new Set(target);

  return source.filter((e) => {
    return !set2.has(e);
  });
}

function differenceAllItems(arr1: any[], arr2: any[]) {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  const result = [
    ...arr1.filter((x) => !set2.has(x)),
    ...arr2.filter((y) => !set1.has(y)),
  ];

  return result;
}

export interface StateCondition {
  sourceKey: string;
  fromKey: string;
  sourceDataHandler?: (x) => any;
  targetDataHandler?: (x) => any;
}

export interface SaveQuery {
  alias?: string;
  data: any;
  key: string;
  delete: (x) => void;
}
