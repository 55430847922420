<div class="flex gap-1">
  <p class="text-secondary mt-1 flex-none">{{ '位置' | translate }}：</p>
  <div class="flex-auto overflow-hidden">
    <header class="flex flex-wrap items-baseline">
      <div class="flex gap-1 flex-wrap">
        <app-my-select-option
          value="city"
          [(ngModel)]="active"
          [ngModelOptions]="{ standalone: true }"
        >
          {{ '選擇城市' | translate }}
        </app-my-select-option>
        <app-my-select-option
          value="district"
          [(ngModel)]="active"
          [ngModelOptions]="{ standalone: true }"
        >
          <!-- <ng-container
          *ngIf="choiceDistrictLength$ | async as length; else selectElm"
          >
          {{ '以選' | translate }} {{ length }} {{ '個鄉鎮' | translate }}
        </ng-container>
        <ng-template #selectElm> -->
          {{ '選擇鄉鎮' | translate }}
          <!-- </ng-template> -->
        </app-my-select-option>
        <app-my-select-option
          value="mrt"
          [(ngModel)]="active"
          [ngModelOptions]="{ standalone: true }"
        >
          <!-- <ng-container *ngIf="choiceCategory; else selectCategoryElm">
        {{ choiceCategory.name }}
      </ng-container>
      <ng-template #selectCategoryElm> -->
          {{ '選擇交通' | translate }}
          <!-- </ng-template> -->
        </app-my-select-option>
        <app-my-select-option
          value="liveArea"
          [(ngModel)]="active"
          [ngModelOptions]="{ standalone: true }"
        >
          <!-- <ng-container *ngIf="choiceCategory; else selectCategoryElm">
      {{ choiceCategory.name }}
    </ng-container>
    <ng-template #selectCategoryElm> -->
          {{ '選擇生活圈' | translate }}
          <!-- </ng-template> -->
        </app-my-select-option>
        <app-my-select-option
          value="community"
          [(ngModel)]="active"
          [ngModelOptions]="{ standalone: true }"
        >
          <!-- <ng-container *ngIf="choiceCategory; else selectCategoryElm">
    {{ choiceCategory.name }}
  </ng-container>
  <ng-template #selectCategoryElm> -->
          {{ '選擇社區' | translate }}
          <!-- </ng-template> -->
        </app-my-select-option>
        <app-my-select-option
          value="school"
          [(ngModel)]="active"
          [ngModelOptions]="{ standalone: true }"
        >
          <!-- <ng-container *ngIf="choiceCategory; else selectCategoryElm">
  {{ choiceCategory.name }}
</ng-container>
<ng-template #selectCategoryElm> -->
          {{ '選擇學校' | translate }}
          <!-- </ng-template> -->
        </app-my-select-option>
      </div>
    </header>
    @if (active) {
    <main class="pt-2">
      @switch (active) { @case ('city') {
      <div class="list">
        @if (cities$ | async) {
        <div>
          <ng-container formArrayName="cities">
            @for (city of cities.controls; track city; let i = $index) {
            <ng-container [formGroupName]="i">
              <span
                (click)="checkElm.click()"
                [ngClass]="{
                  'bg-primary text-white': city.get('checked').value
                }"
              >
                {{ city.get('name').value }}
                <input
                  class="hidden"
                  #checkElm
                  type="checkbox"
                  formControlName="checked"
                />
              </span>
            </ng-container>
            }
          </ng-container>
        </div>
        }
      </div>
      } @case ('district') {
      <div>
        <div class="flex flex-col gap-2">
          @if (cityDistrictArray.length > 0) {
          <ng-container formArrayName="districts">
            @for (cityDistrictGroup of cityDistrictArray; track
            cityDistrictGroup) {
            <div class="list">
              <p class="text-secondary">{{ cityDistrictGroup.name }}：</p>
              <div>
                @for ( district of cityDistrictGroup.districts; track district;
                let i = $index) {
                <span
                  [formGroupName]="i + cityDistrictGroup.startIndex"
                  (click)="checkElm.click()"
                  [ngClass]="{
                    'bg-primary text-white':
                      districts.controls[i + cityDistrictGroup.startIndex]
                        ?.value.checked
                  }"
                >
                  {{ district.name }}
                  <input
                    class="hidden"
                    #checkElm
                    type="checkbox"
                    formControlName="checked"
                  />
                </span>
                }
              </div>
            </div>
            }
          </ng-container>
          } @else {
          <span class="text-secondary text-sm">{{
            '請先選擇城市' | translate
          }}</span>
          }
        </div>
      </div>
      } @case ('mrt') {
      <div>
        @if ( transportationFacilitiesCategories$ | async; as
        transportationFacilitiesCategories ) {
        <div class="flex flex-col gap-2">
          <ng-container formArrayName="transportationFacilities">
            @for ( transportationFacilitiesCategory of
            transportationFacilitiesCategories ; track
            transportationFacilitiesCategory) {
            <div class="list">
              <p class="text-secondary">
                {{ transportationFacilitiesCategory.name }}：
              </p>
              <div>
                @for ( transportationFacility of
                transportationFacilitiesCategory.mrts; track
                transportationFacility; let i = $index) {
                <span
                  [formGroupName]="
                    i + transportationFacilitiesCategory.startIndex
                  "
                  (click)="checkElm.click()"
                  [ngClass]="{
                    'bg-primary text-white':
                      transportationFacilities.controls[
                        i + transportationFacilitiesCategory.startIndex
                      ]?.value.checked
                  }"
                >
                  {{ transportationFacility.name }}
                  <input
                    class="hidden"
                    #checkElm
                    type="checkbox"
                    formControlName="checked"
                  />
                </span>
                }
              </div>
            </div>
            }
          </ng-container>
        </div>
        }
      </div>
      } @case ('liveArea') {
      <div class="list">
        @if (liveAreas$ | async) {
        <div>
          <ng-container formArrayName="liveAreas">
            @for (liveArea of liveAreas.controls; track liveArea; let i =
            $index) {
            <ng-container [formGroupName]="i">
              <span
                (click)="checkElm.click()"
                [ngClass]="{
                  'bg-primary text-white': liveArea.get('checked').value
                }"
              >
                {{ liveArea.get('name').value }}
                <input
                  class="hidden"
                  #checkElm
                  type="checkbox"
                  formControlName="checked"
                />
              </span>
            </ng-container>
            }
          </ng-container>
        </div>
        }
      </div>
      } @case ('community') {
      <div class="list">
        @if (communities$ | async) {
        <div>
          <ng-container formArrayName="communities">
            @for (community of communities.controls; track community; let i =
            $index) {
            <ng-container [formGroupName]="i">
              <span
                (click)="checkElm.click()"
                [ngClass]="{
                  'bg-primary text-white': community.get('checked').value
                }"
              >
                {{ community.get('name').value }}
                <input
                  class="hidden"
                  #checkElm
                  type="checkbox"
                  formControlName="checked"
                />
              </span>
            </ng-container>
            }
          </ng-container>
        </div>
        }
      </div>
      } @case ('school') {
      <div class="list">
        <div>
          <ng-container formArrayName="educationSchools">
            @for ( educationSchool of educationSchools.controls; track
            educationSchool; let i = $index) {
            <ng-container [formGroupName]="i">
              <span
                (click)="checkElm.click()"
                [ngClass]="{
                  'bg-primary text-white': educationSchool.get('checked').value
                }"
              >
                {{ educationSchool.get('name').value }}
                <input
                  class="hidden"
                  #checkElm
                  type="checkbox"
                  formControlName="checked"
                />
              </span>
            </ng-container>
            }
          </ng-container>
        </div>
      </div>
      } }
    </main>
    }
  </div>
</div>
