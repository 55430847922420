import { Injectable, inject } from '@angular/core';

import { CommunitiesGQL, CommunityGQL } from '@alan-apps/data-access';
import {
  BaseHttpService,
  cache,
  ICache,
  useI18nRouter,
} from '@nghedgehog/angular-ui';

@Injectable({
  providedIn: 'root',
})
export class CommunityService implements ICache {
  private _http = inject(BaseHttpService);
  private communityGQL = inject(CommunityGQL);
  private communitiesGQL = inject(CommunitiesGQL);
  readonly storageKey = 'CommunityService';
  private _router = useI18nRouter();

  search(query: any) {
    this._router.navigate(['/community'], {
      queryParams: query,
    });
  }

  get(id: number) {
    return this._http.apollo(this.communityGQL.fetch({ id }));
  }

  @cache()
  list(option: PageOption, query = {}) {
    return this._http.apollo(this.communitiesGQL.fetch({ ...option, query }));
  }
}
