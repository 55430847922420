import { NgClass } from '@angular/common';
import { Component, forwardRef, Input, model } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { input } from '@angular/core';

@Component({
  selector: 'app-my-select-option',
  templateUrl: './my-select-option.component.html',
  styleUrls: ['./my-select-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MySelectOptionComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgClass],
})
export class MySelectOptionComponent implements ControlValueAccessor {
  disabled = model<boolean | undefined>();
  value = input<any | undefined>();

  get state(): boolean {
    return this._state;
  }
  set state(value) {
    this._state = value;

    this.onChange(this._state ? this.value() : this._state);
  }

  private _state = false;

  writeValue(value: any) {
    this._state = this.value() === value;
  }
  registerOnChange(fn: (value: any) => any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => any) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled.set(isDisabled);
  }
  private onChange = (_: any) => {
    //
  };
  private onTouched = () => {
    //
  };
}
