<div
  class="flex items-center cursor-pointer border px-2 py-1 border-gray-300 rounded text-sm"
  [ngClass]="{ 'bg-primary text-white': state }"
  (click)="state = !state"
>
  <span>
    <ng-content />
  </span>
  <i class="material-icons mat-size-1">
    {{ state ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
  </i>
</div>
