import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import {
  AllMrtCategoriesGQL,
  AllMrtsGQL,
  MrtCategory,
  SearchLocationsGQL,
} from '@alan-apps/data-access';
import { toNodes } from '@alan-apps/utils';
import { BaseHttpService, cache, ICache } from '@nghedgehog/angular-ui';

@Injectable({
  providedIn: 'root',
})
export class MrtService implements ICache {
  private _http = inject(BaseHttpService);
  private allMrtsGQL = inject(AllMrtsGQL);
  private allMrtCategoriesGQL = inject(AllMrtCategoriesGQL);
  private searchLocations = inject(SearchLocationsGQL);
  readonly storageKey = 'MrtService';

  @cache()
  list(category: string, onlyProperty = false) {
    return this._http.apollo(this.allMrtsGQL.fetch({ category, onlyProperty }));
  }

  @cache()
  getAllCategory(onlyProperty = false) {
    return this._http.apollo(this.allMrtCategoriesGQL.fetch({ onlyProperty }));
  }

  @cache()
  getAllMassTransit() {
    return this._http
      .apollo(
        this.searchLocations.fetch({
          query: { type: 'massTransit' },
          onlyProperty: true,
        }),
      )
      .pipe(toNodes()) as Observable<MrtCategory[]>;
  }
}
