import { AsyncPipe, NgClass } from '@angular/common';
import { Component, Input, ViewEncapsulation, inject } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { combineLatest, shareReplay, takeUntil, tap } from 'rxjs';

import {
  ControlContainerProvider,
  LoadingBlockDirective,
  SkeletonItemComponent,
} from '@nghedgehog/angular-ui';
import { TranslateModule } from '@ngx-translate/core';

import { OrderService } from '../../../../service/order.service';
import { PropertyService } from '../../../../service/property.service';
import {
  StateConditionHandler,
  toLiteral,
} from '../../../../service/state-condition.handler';
import { SystemService } from '../../../../service/system.service';
import { MultiSelectAddressComponent } from '../../multi-select-address/multi-select-address.component';
import { MySelectOptionComponent } from '../../my-select-option/my-select-option.component';
import { BaseSearchPropertiesComponent } from '../base-search-properties.component';
import { input } from '@angular/core';

@Component({
  selector: 'app-rent-house',
  templateUrl: './rent-house.component.html',
  styleUrls: ['./rent-house.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ControlContainerProvider],
  standalone: true,
  imports: [
    LoadingBlockDirective,
    MultiSelectAddressComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    MySelectOptionComponent,
    SkeletonItemComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class RentHouseComponent extends BaseSearchPropertiesComponent {
  private _system = inject(SystemService);
  public controlContainer = inject(ControlContainer);
  public _property = inject(PropertyService);
  private _route = inject(ActivatedRoute);
  private orderService = inject(OrderService);
  onlyProperty = input(false);
  expanded = input(false);
  loadingSkeletons = [
    ['w-full', 'w-24', 'w-32'],
    ['w-full', 'w-full', 'w-32'],
    ['w-full', 'w-full', 'w-full'],
    ['w-full', 'w-24', 'w-32'],
    ['w-full', 'w-32', 'w-full'],
    ['w-full', 'w-full', 'w-32'],
    ['w-full', 'w-24', 'w-full'],
    ['w-full', 'w-24', 'w-32'],
    ['w-full', 'w-24', 'w-32'],
    ['w-full', 'w-32', 'w-full'],
    ['w-full', 'w-full', 'w-32'],
    ['w-full', 'w-full', 'w-full'],
    ['w-full', 'w-24', 'w-full'],
  ];

  moreActive = 'parkingTypes';

  get formGroup() {
    return this.controlContainer.control;
  }

  get isRent() {
    return this.formGroup.get('classification').value !== 1;
  }

  get lifeFunctions() {
    return this.formGroup.get('lifeFunctions') as FormArray;
  }

  get parkingTypes() {
    return this.formGroup.get('parkingTypes') as FormArray;
  }

  get devices() {
    return this.formGroup.get('devices') as FormArray;
  }

  get furnitures() {
    return this.formGroup.get('furnitures') as FormArray;
  }

  get faces() {
    return this.formGroup.get('faces') as FormArray;
  }

  get pet() {
    return this.formGroup.get('pet') as FormArray;
  }

  get shortest() {
    return this.formGroup.get('shortest') as FormArray;
  }

  get smoke() {
    return this.formGroup.get('smoke') as FormArray;
  }

  get publicUtilities() {
    return this.formGroup.get('publicUtilities') as FormArray;
  }

  get ages() {
    return this.formGroup.get('ages') as FormArray;
  }

  get floors() {
    return this.formGroup.get('floors') as FormArray;
  }

  get prices() {
    return this.formGroup.get('prices') as FormArray;
  }

  get styles() {
    return this.formGroup.get('styles') as FormArray;
  }

  get patterns() {
    return this.formGroup.get('patterns') as FormArray;
  }

  get educationSchools() {
    return this.formGroup.get('educationSchools') as FormArray;
  }

  get patternFeatures() {
    return this.formGroup.get('patternFeatures') as FormArray;
  }

  get shapes() {
    return this.formGroup.get('shapes') as FormArray;
  }

  get usages() {
    return this.formGroup.get('usages') as FormArray;
  }

  get kinds() {
    return this.formGroup.get('kinds') as FormArray;
  }

  get categories() {
    return this.formGroup.get('categories') as FormArray;
  }

  get areas() {
    return this.formGroup.get('areas') as FormArray;
  }

  shapes$ = this.orderService
    .getShapes()
    .pipe(this._system.addCheckFormArrayTap(this.shapes), shareReplay(1));

  publicUtilities$ = this.orderService
    .getPublicUtilities()
    .pipe(
      this._system.addCheckFormArrayTap(this.publicUtilities),
      shareReplay(1),
    );

  kinds$ = this.orderService
    .getKinds()
    .pipe(this._system.addCheckFormArrayTap(this.kinds), shareReplay(1));

  usages$ = this.orderService
    .getUsages()
    .pipe(this._system.addCheckFormArrayTap(this.usages), shareReplay(1));

  categories$ = this.orderService
    .getKinds()
    .pipe(this._system.addCheckFormArrayTap(this.categories), shareReplay(1));

  devices$ = this.orderService
    .getDevices()
    .pipe(this._system.addCheckFormArrayTap(this.devices), shareReplay(1));

  furnitures$ = this.orderService
    .getFurnitures()
    .pipe(this._system.addCheckFormArrayTap(this.furnitures), shareReplay(1));

  faces$ = this.orderService
    .getFaces()
    .pipe(this._system.addCheckFormArrayTap(this.faces), shareReplay(1));

  lifeFunctions$ = this.orderService.getLifeFunctions().pipe(
    tap((lifeFunctions) => {
      lifeFunctions.forEach((lifeFunction) => {
        switch (lifeFunction.type) {
          case 'others':
            this.lifeFunctions.push(
              this._system.createCheckedFormGroup(lifeFunction),
            );
            break;
          case 'school':
            this.educationSchools.push(
              this._system.createCheckedFormGroup(lifeFunction),
            );
            break;
          case 'style':
            this.styles.push(this._system.createCheckedFormGroup(lifeFunction));
            break;
          case 'pattern-feature':
            this.patternFeatures.push(
              this._system.createCheckedFormGroup(lifeFunction),
            );
            break;
          // case 'support-setup':
          //   this.supportSetups.push(
          //     this._system.createCheckItemGroup(lifeFunction, initState),
          //   );
          //   break;
          default:
            break;
        }
      });
    }),
    shareReplay(1),
  );

  allRequests$ = combineLatest([
    this.shapes$,
    this.publicUtilities$,
    this.kinds$,
    this.usages$,
    this.categories$,
    this.devices$,
    this.furnitures$,
    this.faces$,
    this.lifeFunctions$,
  ]);

  constructor() {
    super();

    combineLatest([
      this._route.queryParams,
      this.shapes$,
      this.publicUtilities$,
      this.kinds$,
      this.usages$,
      this.categories$,
      this.devices$,
      this.furnitures$,
      this.faces$,
      this.lifeFunctions$,
    ])
      .pipe(
        tap(([params]) => {
          Object.entries(params).forEach((curr) => {
            this._system.checkRouteParams(curr, this.formGroup);
          });
        }),
        this.takeUntilAppDestroy(),
      )
      .subscribe();
  }

  initQuery() {
    const stateHandler = new StateConditionHandler(
      this,
      this._route.snapshot.queryParams,
    );

    this.queries = [
      ...stateHandler.checkQueryState({
        sourceKey: 'pattern',
        fromKey: 'patterns',
        sourceDataHandler: toLiteral,
        targetDataHandler: toLiteral,
      }),
      ...stateHandler.checkQueryState({
        sourceKey: 'area',
        fromKey: 'areas',
      }),
      ...stateHandler.checkQueryState({
        sourceKey: 'price',
        fromKey: 'prices',
      }),
    ];
  }
}
